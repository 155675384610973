import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import { IClientError, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import {
    MarketGroupIds,
    sortSetWinnerMarkets,
    sortByFavoriteId,
    MarketGroupData,
} from "@finbackoffice/fe-core";
import {
    BetSlipContext,
    BrowserStorageContext,
    BrowserStorageIdsEnum,
    convertMarketsIntoMarketGroups,
    IGameData,
    sortMarketGroups,
    useWebsocketUpdates,
    WebsocketContext,
} from "@finbackoffice/site-core";
import { EventStatus, MarketStatus, MarketOutcomeStatus } from "@finbackoffice/enums";
import { InitialDataContext, MarketUpdatesContext } from "contexts";
import { Feed } from "hooks";
import { RouteNames } from "utils/constants";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

export const useGame = (gameId: number) => {
    const router = useRouter();
    const { setCurrentGameStatus } = useContext(InitialDataContext);
    const { get, set } = useContext(BrowserStorageContext);
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const [gameData, setGameData] = useState<IGameData | null>(null);
    const [favoriteMarketsIds, setFavoriteMarketsIds] = useState<string[]>([]);
    const [markets, setMarkets] = useState<MarketGroupData[]>([]);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const { socketClient } = useContext(WebsocketContext);
    const feed = useGetCurrentFeedType();

    const isInitial = useRef(false);

    const sportId = gameData?.sport_id;
    const homeName = gameData?.home_team?.name || "";
    const awayName = gameData?.away_team?.name || "";

    const gameUpdateHandler = useCallback(
        (data: IEventFeedResponse, error: IClientError) => {
            if (!error) {
                setGameData((state) => {
                    let updatedGame: IGameData = { ...data, market_groups: {} };

                    updatedGame.markets = socketClient.mergeEventMarkets(
                        state?.markets || [],
                        updatedGame.markets,
                    );

                    updatedGame.markets?.sort(sortSetWinnerMarkets);

                    updatedGame = convertMarketsIntoMarketGroups(updatedGame, MarketGroupIds);

                    updatedGame = sortMarketGroups(updatedGame);

                    return updatedGame;
                });
            } else if (error.statusCode === 404) {
                router.replace({
                    pathname: `/${RouteNames.SPORT}/${feed}`,
                });
            }
        },
        [feed, router, socketClient],
    );
    const payload = useMemo(() => ({ event_id: gameId }), [gameId]);
    useWebsocketUpdates("event", gameUpdateHandler, true, payload);

    useEffect(() => {
        return () => {
            if (gameId) {
                setGameData(null);
            }
        };
    }, [gameId]);

    useEffect(() => {
        if (feed === Feed.live) {
            const savedFavMarkets = get(BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS);
            if (gameId && savedFavMarkets && sportId) {
                setFavoriteMarketsIds(savedFavMarkets[sportId] || []);
            }
        } else if (feed === Feed.prematch) {
            const savedFavMarkets = get(BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS);
            if (gameId && savedFavMarkets && sportId) {
                setFavoriteMarketsIds(savedFavMarkets[sportId] || []);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feed, gameId, sportId]);

    useEffect(() => {
        const updateMarkets = () => {
            const gameMarketGroups: MarketGroupData[] = gameData?.market_groups
                ? Object.values(gameData.market_groups)
                : [];

            gameMarketGroups.sort(sortByFavoriteId(favoriteMarketsIds));

            setMarkets(gameMarketGroups);
        };

        if (gameId && gameData) {
            updateMarkets();

            if (
                [
                    EventStatus.Ended,
                    EventStatus.Live,
                    EventStatus.Suspended,
                    EventStatus.Settled,
                ].indexOf(gameData.status) !== -1
            ) {
                setCurrentGameStatus(EventStatus.Live);
            } else {
                setCurrentGameStatus(EventStatus.Prematch);
            }

            if (feed === Feed.live) {
                if (
                    gameData.status === EventStatus.Settled ||
                    (!isInitial.current &&
                        (gameData.status === EventStatus.Ended ||
                            gameData.status === EventStatus.Prematch))
                ) {
                    router.replace(`/${RouteNames.SPORT}/${RouteNames.LIVE}`);
                }
            }
        }

        if (gameId && !isInitial.current) {
            isInitial.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameData, favoriteMarketsIds, gameId, feed]);

    const addBet = useCallback(
        (
            marketGroupId: string,
            marketName: string,
            marketStatus: MarketStatus,
            marketId: number,
            outcome: IMarketOutcomeFeedResponse,
        ) => {
            if (!isOutcomeSelected(outcome.id)) {
                addBetItem({
                    gameId,
                    marketGroupId,
                    marketId,
                    outcomeId: outcome.id,
                    team1Name: homeName,
                    team2Name: awayName,
                    marketName,
                    marketStatus,
                    outcomeName: outcome.name ?? "",
                    outcomeValue: outcome.odds,
                    outcomeStatus: MarketOutcomeStatus.Active,
                    outcomeInitValue: outcome.odds,
                    stake: "",
                    loading: false,
                    accepted: false,
                });
                subscribeMarket(gameId, marketId, outcome.id);
            } else {
                removeBetItem(outcome.id);
                unsubscribeMarket(outcome.id);
            }
        },
        [
            awayName,
            isOutcomeSelected,
            gameId,
            homeName,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    const getUpdatedFavorites = useCallback(
        (storage: Storage, marketType: string) => {
            if (sportId) {
                if (storage && storage.hasOwnProperty(sportId)) {
                    const index: number = storage[sportId].indexOf(marketType);

                    if (index === -1) {
                        storage[sportId].push(marketType);
                    } else {
                        storage[sportId].splice(index, 1);
                    }
                } else {
                    storage[sportId] = [marketType];
                }
            }

            return storage;
        },
        [sportId],
    );

    const onFavoriteItemClick: (marketType: string) => void = useCallback(
        (marketType: string): void => {
            if (sportId) {
                const savedFavPrematchMarkets = get(
                    BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS,
                );
                const savedFavLiveMarkets = get(BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS);
                let storage =
                    feed === Feed.prematch
                        ? (savedFavPrematchMarkets ?? {})
                        : (savedFavLiveMarkets ?? {});

                storage = getUpdatedFavorites(storage, marketType);

                setFavoriteMarketsIds(storage[sportId]);
                set(
                    feed === Feed.prematch
                        ? BrowserStorageIdsEnum.FAVORITE_PREMATCH_MARKETS_IDS
                        : BrowserStorageIdsEnum.FAVORITE_LIVE_MARKETS_IDS,
                    JSON.stringify(storage),
                );
            }
        },
        [feed, get, getUpdatedFavorites, set, sportId],
    );

    return { gameData, addBet, onFavoriteItemClick, favoriteMarketsIds, markets };
};
