import { EventStatus, UserStatus } from "@finbackoffice/enums";
import {
    IBetItemData,
    formatAppNameText,
    ProfileLayoutEnum,
    ISiteConfigLayouts,
    MenuButtonsEnum,
} from "@finbackoffice/fe-core";
import {
    AuthContext,
    BetSlipContext,
    ConfigContext,
    ModalsContext,
    SiteNotificationsContext,
    UserAccountContext,
    useRuntimeConfig,
    useSiteConfig,
    useTranslation,
    ChatContext,
    CrmContext,
} from "@finbackoffice/site-core";
import classnames from "classnames";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import {
    FC,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Button from "components/base/button/Button";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import { InitialDataContext, NotificationContext } from "contexts";
import { useLiveSports, useSportResults } from "hooks";
import { validationRules } from "utils/betValidation";
import { ModalTypes, RouteNames, RouterQuery } from "utils/constants";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import Account from "components/account/Account";
import Translate from "../base/translate/Translate";
import Compact from "./layouts/compact/Compact";
import styles from "./menu.module.sass";

const LoginPanel = dynamic(() => import("./login-panel/LoginPanel"), {
    loading: () => <PreloadComponent style={{ height: 422, margin: 0, border: 0 }} />,
    ssr: false,
});
const Verification = dynamic(() => import("components/account/verification/Verification"), {
    ssr: false,
});

const BetSlip = dynamic(() => import("../pages/sport/betslip/BetSlip"), {
    ssr: false,
});

const FloatingBetslip = dynamic(() => import("./floating-betslip/FloatingBetslip"), {
    ssr: false,
});

const Game = dynamic(() => import("components/pages/sport/base/game/Game"), {
    ssr: false,
});

type MenuScopeType = "default" | "sport" | "casino";

type IMenuContext = {
    betProcessing: boolean;
    betCountChange: boolean;
    onBetSlipButtonClickHandler: () => void;
    handleLiveButtonClick: () => void;
    handleSportButtonClick: () => void;
    hasBetSlipError: boolean;
    liveButtonForceActive: boolean;
    currentModal: string | null;
    betItems: IBetItemData[];
    menuScope: MenuScopeType;
};

export const MenuContext = createContext<IMenuContext>(null as any);

const Menu: FC = () => {
    useLiveSports();
    useSportResults();
    const { t } = useTranslation();
    const router = useRouter();
    const { loadNotifications } = useContext(SiteNotificationsContext);
    const { userProfileStatus, loadProfile, isKycVerified, isEmailVerified, isPhoneVerified } =
        useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { unreadMessages, chatStats } = useContext(ChatContext);
    const { unreadMessagesCount } = useContext(CrmContext);
    const { currentModal, setCurrentModal, loginModalRef, accountModalRef } =
        useContext(ModalsContext);
    const { betItems, betProcessing, betSlipError } = useContext(BetSlipContext);
    const [betCountChange, setBetCountChange] = useState(false);
    const { notice } = useContext(NotificationContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const verificationModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const betSlipModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const gameModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const { currentGameStatus, setCurrentGameStatus, activeSportGame, setActiveSportGame } =
        useContext(InitialDataContext);
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const [liveButtonForceActive, setLiveButtonForceActive] = useState<boolean>(
        currentGameStatus === EventStatus.Live,
    );
    const modalType = router.query?.type;
    const menuScope = useMemo(() => {
        const chunk = router.pathname.split("/")[1] as MenuScopeType;
        switch (chunk) {
            case "casino":
            case "sport":
                return chunk as MenuScopeType;
            default:
                return "default";
        }
    }, [router.pathname]);

    useEffect(() => {
        if (router.query?.type === RouterQuery.Login && loginModalRef.current) {
            loginModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query?.type]);

    useEffect(() => {
        if (liveButtonForceActive) {
            setLiveButtonForceActive(false);
        }

        if (
            [
                ModalTypes.BET_SLIP,
                ModalTypes.ACCOUNT,
                ModalTypes.LOGIN,
                ModalTypes.SIGNUP,
                ModalTypes.SETTINGS,
                ModalTypes.GAME,
            ].includes(currentModal || "")
        ) {
            setCurrentModal(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.pathname]);

    useEffect(() => {
        if (currentGameStatus === EventStatus.Live) {
            setLiveButtonForceActive(true);
            setCurrentGameStatus(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGameStatus]);

    useEffect(() => {
        if (activeSportGame.gameId && !gameModalRef.current?.isOpened) {
            gameModalRef.current?.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSportGame.gameId]);

    useEffect(() => {
        if (betItems.length) {
            setBetCountChange(true);

            setTimeout(() => {
                setBetCountChange(false);
            }, 400);
        }
    }, [betItems.length]);

    useEffect(() => {
        if (userProfileStatus) {
            if (modalType === ModalTypes.ACCOUNT) {
                accountModalRef.current?.open();
            } else if (
                (siteRegistrationConfig?.require_email_verification && !isEmailVerified) ||
                (siteRegistrationConfig?.require_phone_verification && !isPhoneVerified)
            ) {
                verificationModalRef.current?.open();
            } else if (siteRegistrationConfig?.require_id_verification && !isKycVerified) {
                notice(
                    {
                        type: "error",
                        title: t("verification_subHeading"),
                        message: t("verification_id_description"),
                    },
                    "modal",
                );
            }

            loadNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileStatus]);

    const onProfileClick = useCallback(() => {
        if (router.asPath.includes(`/${RouteNames.ACCOUNT}`)) {
            router.push(`/${RouteNames.SPORT}/${RouteNames.LIVE}`);
        } else if (currentModal === ModalTypes.ACCOUNT) {
            setCurrentModal(null);
        } else {
            loadProfile();
            accountModalRef.current?.open();
        }
    }, [router, currentModal, setCurrentModal, loadProfile, accountModalRef]);

    const onBetSlipButtonClickHandler = useCallback(() => {
        if (currentModal === ModalTypes.BET_SLIP) {
            setCurrentModal(null);
        } else {
            betSlipModalRef.current?.open();
        }
    }, [currentModal, setCurrentModal]);

    const handleLiveButtonClick = useCallback(() => {
        const pathToLive = `/${RouteNames.SPORT}/${RouteNames.LIVE}`;
        const pathToCalendar = `/${RouteNames.SPORT}/${RouteNames.CALENDAR}`;
        const isLiveView = router.pathname.includes(pathToLive);
        const isCalendarView = router.pathname.includes(pathToCalendar);
        if (!(isLiveView || isCalendarView)) {
            router.push(pathToLive);
        } else if (isLiveView && !!router.query.liveGameRoutes) {
            router.push(`${pathToLive}/${router.query.liveGameRoutes[0]}`);
        }
    }, [router]);

    const handleSportButtonClick = useCallback(() => {
        const pathToPrematch = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}`;
        const isPrematchView = router.pathname.includes(pathToPrematch);
        if (!isPrematchView) {
            router.push(`${pathToPrematch}/${RouteNames.TOP}`);
        } else if (!!router.query.tournamentId || !!router.query.gameId) {
            router.push(`${pathToPrematch}/${RouteNames.ALL}/${router.query.sportName}`);
        }
    }, [router]);

    const hasBetSlipError = useMemo(
        () =>
            validationRules.doesAnyBetHaveError(betItems, betSlipError) ||
            !!validationRules.getRemovableBets(betItems).length,
        [betSlipError, betItems],
    );

    const renderLoginButton = useMemo(() => {
        const onLoginClick = () => {
            if (currentModal === ModalTypes.LOGIN) {
                setCurrentModal(null);
            } else {
                loginModalRef.current?.open();
            }
        };
        return (
            <>
                <Button
                    type="button"
                    onClick={onLoginClick}
                    className={classnames(
                        styles.login,
                        currentModal === ModalTypes.LOGIN && styles.loginActive,
                    )}
                    data-testid="header-login-button">
                    <Translate tid="menu_login" />
                    <Svg
                        src={`/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/icons/header-login-icon.svg`}
                        wrapper="span"
                        className="svg-icon"
                    />
                </Button>
                <ErrorBoundary name={LoginPanel.name}>
                    <Modal
                        ref={loginModalRef}
                        styleClass={styles.loginModalCompact}
                        type={ModalTypes.LOGIN}
                        maskClosable={false}
                        animateVariant="bottom">
                        <LoginPanel />
                    </Modal>
                </ErrorBoundary>
            </>
        );
    }, [currentModal, COMMON_SITE_CONFIGS.appName, loginModalRef, setCurrentModal]);

    const renderProfileButton = useMemo(() => {
        const unreadMessagesMemo =
            unreadMessages.length + (chatStats?.unread_message ?? 0) + unreadMessagesCount;
        return (
            <>
                <Button
                    type="button"
                    onClick={onProfileClick}
                    className={classnames(
                        styles.profile,
                        siteLayoutsConfig.profile.type === ProfileLayoutEnum.Default &&
                            styles.profileDefault,
                        (currentModal === ModalTypes.ACCOUNT ||
                            router.asPath.includes(`/${RouteNames.ACCOUNT}`)) &&
                            styles.loginActive,
                    )}
                    data-testid="open-user-panel-button">
                    <Translate tid="menu_profile" />
                    <span>
                        <Svg
                            src={`/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/desktop/icons/header-login-icon.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                        {Boolean(unreadMessagesMemo) && (
                            <span className={styles.unreadMsgsCount}>{unreadMessagesMemo}</span>
                        )}
                        {userProfileStatus && userProfileStatus !== UserStatus.Registered && (
                            <Svg
                                src="/common/desktop/base-icons/question-icon.svg"
                                wrapper="span"
                                className={styles.verificationReminder}
                            />
                        )}
                    </span>
                </Button>

                <ErrorBoundary name={Account.name}>
                    <Modal
                        ref={accountModalRef}
                        styleClass={classnames(
                            siteLayoutsConfig.profile.type === ProfileLayoutEnum.Compact &&
                                styles.accountModalCompact,
                        )}
                        type={ModalTypes.ACCOUNT}
                        animateVariant={
                            siteLayoutsConfig.profile.type === ProfileLayoutEnum.Default
                                ? "right"
                                : "bottom"
                        }>
                        <Account />
                    </Modal>
                </ErrorBoundary>

                <Modal
                    ref={verificationModalRef}
                    styleClass="signupModal"
                    type={ModalTypes.VERIFICATION}
                    animateVariant="right">
                    <Verification />
                </Modal>
            </>
        );
    }, [
        onProfileClick,
        siteLayoutsConfig.profile.type,
        currentModal,
        router.asPath,
        COMMON_SITE_CONFIGS.appName,
        userProfileStatus,
        accountModalRef,
        unreadMessages.length,
        chatStats,
        unreadMessagesCount,
    ]);

    const value = useMemo(
        () => ({
            betProcessing,
            betCountChange,
            onBetSlipButtonClickHandler,
            handleLiveButtonClick,
            handleSportButtonClick,
            hasBetSlipError,
            liveButtonForceActive,
            currentModal,
            betItems,
            menuScope,
        }),
        [
            betProcessing,
            betCountChange,
            onBetSlipButtonClickHandler,
            handleLiveButtonClick,
            handleSportButtonClick,
            hasBetSlipError,
            liveButtonForceActive,
            currentModal,
            betItems,
            menuScope,
        ],
    );

    return (
        <ErrorBoundary name={Menu.name}>
            {COMMON_SITE_CONFIGS.betslip.floatingBetslip &&
                siteLayoutsConfig.menu[menuScope].includes(MenuButtonsEnum.BetSlip) && (
                    <FloatingBetslip />
                )}
            <div className={styles.menu}>
                <MenuContext.Provider value={value}>
                    <Compact />
                </MenuContext.Provider>

                {isUserLoggedIn ? renderProfileButton : renderLoginButton}
            </div>
            <Modal
                ref={betSlipModalRef}
                type={ModalTypes.BET_SLIP}
                animateVariant="bottom"
                styleClass={styles.betslipModal}>
                <BetSlip />
            </Modal>
            <Modal
                ref={gameModalRef}
                type={ModalTypes.GAME}
                animateVariant="bottom"
                closeButton
                priority
                styleClass={styles.liveGameModal}
                onClose={() => {
                    const query = { ...router.query };
                    delete query.gameId;
                    router.replace({ query }, undefined, {
                        shallow: true,
                    });
                    setActiveSportGame({
                        tournamentGames: null,
                        gameId: null,
                    });
                }}>
                <div className={styles.liveGameContainer}>
                    {activeSportGame.gameId && (
                        <Game id={parseInt(activeSportGame.gameId)} variant="modal" />
                    )}
                </div>
            </Modal>
            {/* <Modal
                ref={gameModalRef}
                type={ModalTypes.GAME}
                animateVariant="bottom"
                closeButton
                styleClass={styles.casinoGameModal}
                onClose={() => {
                    setActiveGame(null);
                    router.push({ pathname: router.pathname }, undefined, { shallow: true });
                }}>
                <>{activeGame && <CasinoGame />}</>
            </Modal> */}
        </ErrorBoundary>
    );
};
export default Menu;
