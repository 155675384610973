import {
    BrowserStorageContext,
    FavoriteEventsProvider,
    FeedContext,
} from "@finbackoffice/site-core";
import {
    createContext,
    Dispatch,
    useMemo,
    FC,
    PropsWithChildren,
    useState,
    SetStateAction,
    useContext,
} from "react";
import { EventStatus } from "@finbackoffice/enums";
import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { useRouter } from "next/router";
import { useSocketStorage, Feed } from "hooks";

export interface IInitialDataContext {
    currentGameStatus: EventStatus.Prematch | EventStatus.Live | null;
    setCurrentGameStatus: (val: EventStatus.Live | EventStatus.Prematch | null) => void;
    activeSportGame: IActiveSportGameState;
    setActiveSportGame: Dispatch<SetStateAction<IActiveSportGameState>>;
    setSiteHeaderHeight: (val: number) => void;
    siteHeaderHeight: number;
}

export const InitialDataContext = createContext<IInitialDataContext>(null as any);

type IActiveSportGameState = {
    gameId: string | null;
    tournamentGames: IEventFeedResponse[] | null;
};

export const InitialDataProvider: FC<PropsWithChildren> = ({ children }) => {
    const router = useRouter();
    const defaultActiveSportGame = useMemo(() => {
        if (!router.pathname.includes("[gameId]") && router.query.gameId) {
            return router.query.gameId as string;
        }
        return null;
    }, [router.pathname, router.query.gameId]);
    const [activeSportGame, setActiveSportGame] = useState<IActiveSportGameState>({
        gameId: defaultActiveSportGame,
        tournamentGames: null,
    });
    const [siteHeaderHeight, setSiteHeaderHeight] = useState(0);
    const [currentGameStatus, setCurrentGameStatus] = useState<
        EventStatus.Live | EventStatus.Prematch | null
    >(null);
    const socketStorage = useSocketStorage(Feed.live);
    const { hasLiveFeedGames } = useContext(FeedContext);
    const browserStorage = useContext(BrowserStorageContext);

    const value = useMemo(
        () => ({
            siteHeaderHeight,
            activeSportGame,
            setActiveSportGame,
            setSiteHeaderHeight,
            setCurrentGameStatus,
            currentGameStatus,
        }),
        [siteHeaderHeight, currentGameStatus, activeSportGame],
    );

    return (
        <InitialDataContext.Provider value={value}>
            <FavoriteEventsProvider
                browserStorage={browserStorage}
                socketStorage={socketStorage}
                liveFeedReady={hasLiveFeedGames}>
                {children}
            </FavoriteEventsProvider>
        </InitialDataContext.Provider>
    );
};
