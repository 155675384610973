import {
    createContext,
    FC,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { IBetItemData } from "@finbackoffice/fe-core";
import { ISocketResponse, ISubscriptionPayloadParams } from "@finbackoffice/websocket-client";
import {
    AuthContext,
    BetSlipContext,
    BrowserStorageIdsEnum,
    ConfigContext,
    UserAccountContext,
    WebsocketContext,
} from "@finbackoffice/site-core";
import { formatAmount } from "components/base/currency-formater/CurrencyFormater";

type IMarketUpdatesContext = {
    subscribeMarket: (gameId: number, marketId: number, outcomeId: number) => void;
    unsubscribeMarket: (outcomeId: number) => void;
    unsubscribeAllMarkets: () => void;
};

type IMapPayload = {
    market_id: number;
    outcome_id: number;
    handler: (...args: any[]) => any;
};

export const MarketUpdatesContext = createContext<IMarketUpdatesContext>(null as any);

export const MarketUpdatesProvider: FC<PropsWithChildren> = ({ children }) => {
    const { socketClient, connected } = useContext(WebsocketContext);
    const { currentWalletId } = useContext(UserAccountContext);
    const { isUserLoggedIn } = useContext(AuthContext);
    const { siteCurrencyFormat } = useContext(ConfigContext);
    const { addBetItem, removeBetItem, updateBetItem, betItems } = useContext(BetSlipContext);
    const [MARKETS_MAP] = useState(new Map<number, IMapPayload>());

    const unsubscribeMarket = useCallback(
        (outcome_id: number) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const [event_id, subscription] of MARKETS_MAP.entries()) {
                if (subscription.outcome_id === outcome_id) {
                    socketClient.off("market", subscription.handler);
                    MARKETS_MAP.delete(event_id);
                    return;
                }
            }
        },
        [MARKETS_MAP, socketClient],
    );

    const subscribeMarket = useCallback(
        (event_id: number, market_id: number, outcome_id: number, shouldUpdateMap = true) => {
            const subscription = MARKETS_MAP.get(event_id);

            if (subscription) {
                // delete previously subscribed market, because only one subscription is allowed per market
                socketClient.off("market", subscription.handler);
                MARKETS_MAP.delete(event_id);
            }

            // NOTE: new function MUST be created for each subscription, otherwise it won't work
            function handler(market: ISocketResponse<"market">, error: any) {
                if (error) {
                    removeBetItem(outcome_id);
                    unsubscribeMarket(outcome_id);
                    return;
                }

                const outcome = market.outcomes.find((outcome) => outcome.id === outcome_id);

                if (outcome) {
                    const updatedBet: Partial<IBetItemData> = {
                        // TODO: add proper type
                        team1Name: market.home_team?.name ?? "",
                        team2Name: market.away_team?.name ?? "",
                        marketName: market.name,
                        outcomeId: outcome.id,
                        outcomeName: outcome.name ?? "",
                        outcomeValue: outcome.odds,
                        marketStatus: market.status,
                    };

                    if (isUserLoggedIn && market.limits) {
                        updatedBet.limits = {
                            ...market.limits,
                            min_bet: formatAmount({
                                format: siteCurrencyFormat?.[market.limits.currency] ?? null,
                                amount: market.limits.min_bet,
                                withCode: false,
                            }).replaceAll(",", ""),
                        };
                    }

                    updateBetItem(updatedBet);
                }
            }

            const payload: ISubscriptionPayloadParams<"market"> = [
                {
                    event_id,
                    market_id,
                    outcome_id,
                },
            ];

            if (currentWalletId) {
                payload[0] = { ...payload[0], wallet_id: currentWalletId };
            }

            socketClient.on("market", handler, payload[0]);
            if (shouldUpdateMap) {
                MARKETS_MAP.set(event_id, { market_id, outcome_id, handler });
            }
        },
        [
            MARKETS_MAP,
            currentWalletId,
            socketClient,
            unsubscribeMarket,
            isUserLoggedIn,
            siteCurrencyFormat,
            removeBetItem,
            updateBetItem,
        ],
    );

    const unsubscribeAllMarkets = useCallback(() => {
        // eslint-disable-next-line no-restricted-syntax
        for (const [, subscription] of MARKETS_MAP.entries()) {
            socketClient.off("market", subscription.handler);
        }
        MARKETS_MAP.clear();
    }, [MARKETS_MAP, socketClient]);

    useEffect(() => {
        if (connected) {
            const storageBetItems = sessionStorage.getItem(BrowserStorageIdsEnum.BET_ITEMS) ?? "[]";
            const parsedArr = JSON.parse(storageBetItems);
            const currentBetItems = betItems.length ? betItems : parsedArr;

            if (currentBetItems?.length) {
                for (let i = 0; i < currentBetItems.length; ++i) {
                    addBetItem(currentBetItems[i]);
                    subscribeMarket(
                        currentBetItems[i].gameId,
                        currentBetItems[i].marketId,
                        currentBetItems[i].outcomeId,
                    );
                }
            }

            if (!parsedArr?.length) {
                sessionStorage.removeItem(BrowserStorageIdsEnum.BET_ITEMS);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connected, currentWalletId]);

    const value = useMemo(
        () => ({
            subscribeMarket,
            unsubscribeMarket,
            unsubscribeAllMarkets,
        }),
        [subscribeMarket, unsubscribeMarket, unsubscribeAllMarkets],
    );

    return <MarketUpdatesContext.Provider value={value}>{children}</MarketUpdatesContext.Provider>;
};
